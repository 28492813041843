import React, { useEffect, useState } from "react";
import { Box, Typography, Link, Divider, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  findTextInNavItems,
  ModalHelpTitle,
  modalHelpTitleTranslations,
} from "../../Components/PageComponents/Help/helpUtils";
import { GetQueryHelpPageKeysSnippet } from "../../Api/Help/apiHelpSnippets";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import {
  getQueryHelpPageContent,
  getQueryHelpPageKeys,
} from "../../Api/Help/apiHelpGetQueries";
import { useLanguageContext } from "../../context/LanguageContext";
import Button from "../../Components/MaterialUI/Button";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import cssFontStyles from "../../Global/Styles/font";
import Modal from "../../Components/MaterialUI/Modal";
import ReportBugProposeAnImprovementForm from "../../Components/PageComponents/Help/ReportBugProposeAnImprovementForm";
import { useTranslatedModalTitle } from "../../Global/Hooks/useTranslations";

interface HelpContentProps {
  locationPath: string;
}

const HelpPage: React.FC<HelpContentProps> = ({ locationPath }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssFontStyles,
  };
  const { setAuthedUser } = useAuthedContext();
  const [modalTitle, setModalTitle] = useState<ModalHelpTitle | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalHelpTitleTranslations);
  const [unsavedEditChanges, setUnsavedEditChanges] = useState<boolean>(false);
  const [mainText, setMainText] = useState<string>(t("Help Section"));
  const [secondaryText, setSecondaryText] = useState<string>("General help content.");
  const [pageContent, setPageContent] = useState<string>("");

  useEffect(() => {
    fetchHelpContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationPath]);

  const setDefaultText = () => {
    setMainText(t("Help Section"));
    setSecondaryText("No specific content found for this section.");
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedEditChanges(true);
    }
  };

  const fetchHelpContent = async () => {
    try {
      const foundPageText = findTextInNavItems(locationPath);

      if (foundPageText) {
        setMainText(foundPageText);
        setSecondaryText(`Content related to ${foundPageText}`);

        const pageKeys = await callApi<GetQueryHelpPageKeysSnippet>({
          query: getQueryHelpPageKeys(),
          auth: { setAuthedUser },
        });
        const currentPage = pageKeys.find((key) => key.page_key === foundPageText);

        if (currentPage) {
          const helpContent = await callApi<string>({
            query: getQueryHelpPageContent(currentPage.id),
            auth: { setAuthedUser },
          });

          setPageContent(helpContent);
        }
      } else {
        setDefaultText();
      }
    } catch (error) {
      console.log("Failed to load Help content", error.message);
      setDefaultText();
    }
  };

  const handleModalOpen = (label: ModalHelpTitle) => {
    setModalTitle(label);
  };

  return (
    <>
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        padding={2}
      >
        <Box component="div">
          <Typography variant="body1" gutterBottom>
            {mainText}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {secondaryText}
          </Typography>
          <Box component="div" marginTop={2}>
            <div dangerouslySetInnerHTML={{ __html: pageContent }} />
          </Box>
        </Box>

        <Box component="div" marginTop={2}>
          <Box component="div" display="flex" justifyContent="center" gap={1} mb={2}>
            <Button
              css={[styles.width100, styles.widthLimit15]}
              variant="outlined"
              onClick={() => handleModalOpen("Report Bug")}
            >
              {t("Report Bug")}
            </Button>

            <Button
              css={[styles.width100, styles.widthLimit15]}
              variant="outlined"
              onClick={() => handleModalOpen("Propose an Improvement")}
            >
              {t("Propose an Improvement")}
            </Button>
          </Box>

          <Box component="div">
            <Divider />
            <Box component="div" marginTop={1.5} paddingLeft={2}>
              <Typography variant="body2">
                <Link
                  component={RouterLink}
                  to="/release-notes"
                  underline="none"
                  color="primary"
                >
                  {t("Release Notes")}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Modal
        open={!!modalTitle}
        onClose={() => setModalTitle(null)}
        fullWidth
        maxWidth="md"
        label={modalTitle ? getTranslatedModalTitle(modalTitle) : ""}
        unsavedChanges={unsavedEditChanges}
        setUnsavedChanges={setUnsavedEditChanges}
      >
        {modalTitle === "Report Bug" ? (
          <ReportBugProposeAnImprovementForm
            type={"bug"}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedEditChanges={setUnsavedEditChanges}
          />
        ) : null}

        {modalTitle === "Propose an Improvement" ? (
          <ReportBugProposeAnImprovementForm
            type={"feature_request"}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedEditChanges={setUnsavedEditChanges}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default HelpPage;
