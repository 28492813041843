import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import QuillEditor from "../../SmallComponents/QuillEditor/QuillEditor";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useState } from "react";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import Button from "../../MaterialUI/Button";
import Alert from "../../MaterialUI/Alert";
import { ReportBugProposeAnImprovementFormInitialValues } from "./helpUtils";
import TextField from "../../MaterialUI/FormFields/TextFields";
import { FileWithPath } from "react-dropzone";
import FileDropzone from "../../SmallComponents/Dropzone/FileDropzone";
import Collapse from "../../MaterialUI/Collapse";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import callApi from "../../../Api/callApi";
import { postQueryReportBugProposeAnImprovement } from "../../../Api/ReportBugProposeAnImpromement/apiReportBugProposeAnImpromementPostQueries";
import { useAuthedContext } from "../../../context/AuthContext";
import { PostQueryReportBugProposeAnImprovementInput } from "../../../Api/ReportBugProposeAnImpromement/apiReportBugProposeAnImpromementInputs";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { useLanguageContext } from "../../../context/LanguageContext";

interface ReportBugFormProps {
  type: "bug" | "feature_request";
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedEditChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReportBugProposeAnImprovementForm: React.FC<ReportBugFormProps> = ({
  type,
  handleSetUnsavedChanges,
  setUnsavedEditChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [editorContent, setEditorContent] = useState<string>("");
  const [files, setFiles] = useState<FileWithPath[] | null>(null);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [title, setTitle] = useState<string>("");

  useDetectFormsUnsavedChanges(
    ReportBugProposeAnImprovementFormInitialValues,
    { title, editorContent, files },
    handleSetUnsavedChanges
  );

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles?.filter((_, i) => i !== index) || null);
  };

  const handleSave = async () => {
    try {
      setFormStatus("loading");
      setAlertMessage(t("Loading..."));

      if (title === "") {
        setFormStatus("warning");
        setAlertMessage(t("Please provide a title before submitting the form."));
        return;
      }

      if (editorContent === "") {
        setFormStatus("warning");
        setAlertMessage(t("Content is required. Please fill in the content field."));
        return;
      }

      const input: PostQueryReportBugProposeAnImprovementInput = {
        type: type,
        title: title,
        content: editorContent,
        ...(files && files.length > 0 ? { files } : {}),
      };

      await callApi<string>({
        query: postQueryReportBugProposeAnImprovement(input),
        auth: { setAuthedUser },
      });

      setFormStatus("success");
      setAlertMessage(t("Successfully Submitted"));
      setUnsavedEditChanges(false);
    } catch (error) {
      console.log(error);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  return (
    <Grid container display="flex" direction="row" justifyContent="space-between" gap={2}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            disabled={formStatus === "success" || formStatus === "loading"}
            label={t("Title")}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2.3}>
          <FileDropzone
            setFiles={(files) => setFiles(files)}
            setErrorMessage={setAlertMessage}
            accept={{ "image/*": [], "application/pdf": [], "application/msword": [] }}
            multiFileUpload
          >
            <Button
              disabled={formStatus === "success" || formStatus === "loading"}
              variant="outlined"
              startIcon={<DriveFolderUploadIcon />}
            >
              {t("Upload File")}
            </Button>
          </FileDropzone>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Collapse in={!!files && files.length > 0}>
          <Grid container spacing={2}>
            {files?.map((file, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  variant="outlined"
                  sx={{
                    borderColor: theme.palette.grey["900"],
                    backgroundColor: "transparent",
                  }}
                >
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Box component="div">
                      <Typography variant="body2">{file.name.split(".")[0]}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {`.${file.type.split("/")[1]}`}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() => handleRemoveFile(index)}
                      aria-label="remove file"
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Collapse>
      </Grid>

      <Grid item xs={12} css={[styles.width100]}>
        <QuillEditor
          label={
            type === "bug"
              ? t("Let us know what went wrong")
              : t("Write Your Improvement Description")
          }
          css={[styles.width100, styles.labelBreak]}
          editorState={editorContent}
          onEditorStateChange={(state: string) => {
            setEditorContent(state);
          }}
          disabled={formStatus === "loading" || formStatus === "success"}
        />
      </Grid>

      <Grid item xs={12}>
        <Stack alignItems="center" justifyContent="center" spacing={2} mt={2}>
          <Button
            css={[styles.width100, styles.widthLimit10]}
            onClick={handleSave}
            disabled={formStatus === "loading" || formStatus === "success"}
          >
            {t("Submit")}
          </Button>
          <Alert
            css={[styles.width100, styles.widthLimit30]}
            message={alertMessage || ""}
            showAlert={!!alertMessage}
            severity={formStatus}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ReportBugProposeAnImprovementForm;
